<template>
  <section
    id="hero"
   style="background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://cdn.tentifly.com/jpg/hero-background.jpg);
   background-repeat: no-repeat;"
  ><div class="background-blur" style="width: 100%; height: 100%; backdrop-filter: blur(2px);">
    <v-container
      class="fill-height px-4 py-12"
      style="padding-bottom: 16px!important;"
    >
      <v-row justify="center">
        <v-col style="border: 1px solid rgba(255, 255, 255, .85); border-radius: 8px; text-align: center; padding: 8px; font-weight: 600; color: rgba(255, 255, 255, .85);">
          {{ $t("hero.endorsing") }}
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="5" md="6" sm="6" class="heroSection">
          <div class="containerHero" style="min-height: 633px;">
            <div class="itemHero" style="color: white;">
              <base-heading
                color="primary"
                :title='$t("hero.title")'
              />

              <div
                style="padding-bottom: 16px; font-weight: 600; font-size: x-large; color: rgba(255, 255, 255, .85);"
              >
              {{ $t("hero.subTitle") }}
              </div>
              <v-col cols="6" lg="6" md="6" sm="6">
                <router-link
                  :to="{ name: 'Contact' }"
                >
                  <base-btn style="border: 2px solid rgba(255, 255, 255, .5)!important;">
                  {{ $t("hero.scheduleDemo") }}
                  </base-btn>
                </router-link>
              </v-col>
              <v-col cols="6" lg="6" md="6" sm="6" style="display: none;">
                  <router-link
                    :to="{ name: 'Contact' }"
                    style="color: #aa004c00"
                  >
                    <base-btn style="background-color: #0000002b!important; border: 2px solid rgba(255, 255, 255, .5)!important;">
                    {{ $t("hero.exploreCases") }}
                    </base-btn>
                  </router-link>
              </v-col>
              <v-col cols="6" lg="6" md="6" sm="6">
                  <router-link
                    :to="{ name: 'Contact' }"
                    style="color: #aa004c00"
                  >
                    <base-btn style="background-color: #0000002b!important; border: 2px solid rgba(255, 255, 255, .5)!important;">
                    {{ $t("hero.contactConsult") }}
                    </base-btn>
                  </router-link>
              </v-col>
              <div style="padding-top: 16px; color: rgba(255, 255, 255, .85);">
                <v-row>
                  <span
                    style="padding: 8px; font-weight: bold;
    font-size: large;"
                    ><v-icon style="margin-right: 4px; margin-bottom: 4px;">mdi-check-bold</v-icon
                    >{{ $t("hero.priceGuarantee") }}</span
                  >
                </v-row>
                <v-row>
                  <span
                    style="padding: 8px; padding-top: 0px; font-weight: bold;
    font-size: large;"
                    ><v-icon style="margin-right: 4px; margin-bottom: 4px;">mdi-check-bold</v-icon
                    >{{ $t("hero.noCreditCard") }}</span
                  >
                </v-row>
                <v-row>
                  <span
                    style="padding: 8px; padding-top: 0px; font-weight: bold;
    font-size: large;"
                    ><v-icon style="margin-right: 4px; margin-bottom: 4px;">mdi-check-bold</v-icon
                    >{{ $t("hero.noRisk30") }}</span
                  >
                </v-row>
                <v-row>
                  <span
                    style="padding: 8px; padding-top: 0px; font-weight: bold;
    font-size: large;"
                    ><v-icon style="margin-right: 4px; margin-bottom: 4px;">mdi-check-bold</v-icon
                    >{{ $t("hero.promoteTentifly") }}</span
                  >
                </v-row>
              </div>
            </div>
          </div> 
        </v-col
        >
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          class="heroSection"
          v-if="screenWidth >= 480"
          style="min-height: 877px;"
        >
          <div class="containerHero" style="min-height: 568px;">
          <div class="marvel-device iphone8 silver">
            <div class="top-bar"></div>
            <div class="sleep"></div>
            <div class="volume"></div>
            <div class="camera"></div>
            <div class="sensor"></div>
            <div class="speaker"></div>
            <div class="screen">
              <v-carousel
                hide-delimiters
                show-arrows-on-hover
                cycle
                height="667px"
              >
                <v-carousel-item
                  v-for="(item, i) in items"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
            </div>
            <div class="home"></div>
            <div class="bottom-bar"></div>
          </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="triangle-bottomleft"
      :style="
        'border-bottom: ' +
          this.screenCalcTriangleBorderBottom +
          'px solid white; border-right: ' +
          this.screenWidth +
          'px solid transparent; margin-top: 36px;'
      "
    />
    </div>
  </section>
</template>
<style scoped>
@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.triangle-bottomleft {
  width: 0;
  height: 0;
  border-bottom: 100px solid white;
  border-right: 100px solid transparent;
}

body {
  margin: 0;
}

h1 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}
p {
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
  color: white;
}
.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
}

.screen .v-window {
  height: unset !important;
}

.containerHero {
  display: flex !important;
  justify-content: space-around !important;
  align-items: flex-start !important;
  height: 877px;
}

@media (max-width: 600px) {
  .containerHero {
    height: 400px !important;
  }
  .heroSection {
    max-width: 448px;
  }
}

@media (max-width: 600px) {
  .containerHero {
    height: 400px !important;
  }
  .heroSection {
    max-width: 448px;
  }
}

.itemHero {
  position: sticky !important;
  top: 8rem !important;
}
</style>
<script>
import "@/styles/devices.min.css";

export default {
  name: "SectionHero",
  data() {
    return {
      items: [
        {
          src: "https://cdn.tentifly.com/png/form_screen1.png"
        },
        {
          src: "https://cdn.tentifly.com/png/form_screen2.png"
        },
        {
          src: "https://cdn.tentifly.com/png/form_screen3.png"
        },
        {
          src: "https://cdn.tentifly.com/png/form_screen5.png"
        },
        {
          src: "https://cdn.tentifly.com/png/form_screen6.png"
        }
      ]
    };
  },
  provide: {
    theme: { isDark: true }
  },
  computed: {
    carouselWidth () {
      if (this.screenWidth > (375 + 8)) {
        // 375 + 8px padding
        return 375;
      } else {
        return this.screenWidth - 8; // correct padding?
      }
    },
    carouselHeight () {
      if (this.carouselWidth >= 375) {
        return 667;
      } else {
        return Math.round(this.carouselWidth * 667 / 375)
      }
    },
    screenWidth: {
      get() {
        return this.$store.state.screenWidth;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenWidth", newValue);
      }
    },
    screenCalcTriangleBorderBottom () {
      return ((100 / 1680) * this.screenWidth); // 1050px for reference
    },
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  }
};
</script>
